@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400&display=swap');

.App {
  font-family: 'Roboto Condensed', sans-serif;
  height: 100vh;
  overflow: hidden;
}

.no-bg{
  background-color: transparent !important;
}

.bg-1{
  background-color: rgb(240,248,255);
}
.color-1{
  color: rgb(216,82,73);
}

.footer-fill {
  height: calc(100vh - 300px);

}
.main-container{
height: 100vh;
overflow-y: auto;
}

/* Nav Animations */
.initial-nav{
  max-width: 200px;
  height: 100vh;
  background-color: rgb(240,248,255);
  border: 1px solid black;
  display: absolute;
  top: -100vh;
  left: -250px;
}

.opened-nav{
  max-width: 200px;
  height: 100vh;
  background-color: rgb(240,248,255);
  border: 1px solid black;
  display: absolute;
  top: -100vh;
  left: 0;
}

.initial-toggle{
  background-color: rgb(248,249,250, 0.8);
  width: 35px;
  height: 35px;
  border: 1px solid black;
  position: absolute;
  top: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.opened-toggle{
  background-color: rgb(248,249,250);
  width: 35px;
  height: 35px;
  border: 1px solid black;
  position: absolute;
  top: 1.5em;
  left: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.opening-animation{
  animation-name: open-nav;
  animation-duration: 0.5s;
  animation-timing-function:ease-out;
}

.opening-animation-toggle{
  animation-name: open-nav-toggle;
  animation-duration: 0.5s;
  animation-timing-function:ease-out;
}

.closing-animation{
  animation-name: close-nav;
  animation-duration: 0.5s;
  animation-timing-function:ease-out;
}

.closing-animation-toggle{
  animation-name: close-nav-toggle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
}

/* END NAV ANIMATIONS */

@media screen and (min-width: 800px) {
  .initial-nav{
    position: relative;
    left: auto;
  }
  .main-container{
    position: relative;
    left: 200px;
    max-width: calc(100vw - 200px);
    overflow-x: hidden;
    }

    .initial-toggle{
      display: none;
    }
    .opened-toggle{
      display: none
    }
}

/* KEYFRAMES */

@keyframes open-nav {
  0% {left: -200px; }
  100% {left: 0;}
}

@keyframes open-nav-toggle {
  0% {left: 0; }
  100% {left: 200px;}
}

@keyframes close-nav {
  0% {left: 0; }
  100% {left:  -200px;}
}

@keyframes close-nav-toggle {
  0% {left: 200px; }
  100% {left: 0;}
}